<template>
  <v-footer class="px-0 footer">
    <section class="w-100">
      <v-container>
        <v-row>
          <!-- Logo and tagline -->
          <v-col cols="12" md="4" lg="3">
            <div class="pb-4">
              <v-img
                class="logo mb-4"
                width="200"
                height="60"
                src="/logo-gotong-royong-indonesia.webp"
                alt="Gotong Royong Indonesia Logo"
                eager
              />
              <p class="text-body-2">
                Mari Jadi Bagian Dari Kegiatan Positif yang Berdampak Pada
                Masyarakat
              </p>
            </div>
          </v-col>

          <!-- Navigation links -->
          <v-col cols="12" offset-md="3" sm="6" md="3">
            <h3 class="text-h6 font-weight-bold text-primary mb-5">
              Tentang kami
            </h3>

            <div
              v-for="(link, index) in navigationLinks"
              :key="index"
              class="nav-link-item my-3"
            >
              <a
                href="#"
                class="d-flex align-center text-decoration-none"
                @click.prevent="navigateToLink(link)"
              >
                <v-icon
                  size="small"
                  color="primary"
                  class="mr-2"
                  v-if="link.icon"
                >
                  {{ link.icon }}
                </v-icon>
                <span>{{ link.title }}</span>
              </a>
            </div>
          </v-col>

          <!-- Contact information -->
          <v-col cols="12" sm="6" md="3">
            <h3 class="text-h6 font-weight-bold text-primary mb-5">
              Hubungi kami
            </h3>

            <div
              v-for="(social, index) in socials"
              :key="index"
              class="social-link-item my-3"
            >
              <a
                :href="social.url"
                target="_blank"
                rel="noopener noreferrer"
                class="d-flex align-center text-decoration-none"
              >
                <v-icon size="small" color="primary" class="mr-2">
                  {{ social.icon }}
                </v-icon>
                <span>{{ social.name }}</span>
              </a>
            </div>

            <!-- Email contact -->
            <div class="social-link-item my-3">
              <a
                href="mailto:kontak@gotongroyong.org"
                class="d-flex align-center text-decoration-none"
              >
                <v-icon size="small" color="primary" class="mr-2">
                  mdi-email-outline
                </v-icon>
                <span>Email</span>
              </a>
            </div>

            <v-btn
              variant="tonal"
              color="primary"
              class="mt-4"
              to="/contact"
              prepend-icon="mdi-message-text-outline"
              size="small"
            >
              Hubungi Kami
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <!-- Copyright bar -->
      <v-divider class="my-4"></v-divider>

      <v-container>
        <v-row class="py-2" justify="center">
          <v-col cols="12" md="6">
            <p class="text-caption text-center text-md-body-2 mb-2 mb-md-0">
              &copy; {{ currentYear }} Gotong Royong Indonesia. Hak Cipta
              Dilindungi.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </v-footer>
</template>

<script lang="ts" setup>
import { useAnchorScroll } from "~/common/composables/useAnchorScroll";
const { navigateToSection } = useAnchorScroll();

const currentYear = ref("");
onMounted(() => {
  currentYear.value = new Date().getFullYear().toString();
});

// Navigation links with sections
const navigationLinks = [
  {
    title: "Kelebihan kami",
    section: "kelebihan-kami",
    path: "/",
    icon: "mdi-star-outline",
  },
  {
    title: "Karya Kami",
    section: "karya-kami",
    path: "/",
    to: "/",
    icon: "mdi-palette-outline",
  },
  {
    title: "Dokumentasi",
    section: "dokumentasi-kegiatan",
    path: "/",
    icon: "mdi-image-outline",
  },
  {
    title: "Kegiatan",
    to: "/activities",
    icon: "mdi-calendar-check-outline",
  },
];

// Social media links
const socials = [
  {
    name: "Instagram",
    icon: "mdi-instagram",
    url: "https://www.instagram.com/gotongroyong_org/",
  },
  {
    name: "Blog",
    icon: "mdi-rss",
    url: "https://blog.gotongroyong.org/category/kisah-kami/",
  },
];

const navigateToLink = (link: any) => {
  if (link.section) {
    navigateToSection(link.path || "/", link.section);
  } else if (link.to) {
    // Use router navigation for regular links
    navigateTo(link.to);
  }
};
</script>

<style lang="scss" scoped>
.footer {
  max-height: fit-content;
  padding-top: 3rem !important;
  padding-bottom: 1rem !important;
  background-color: #fff !important;
}

// Link styling
.nav-link-item a,
.social-link-item a {
  color: rgba(0, 0, 0, 0.87);
  transition:
    transform 0.2s ease,
    color 0.2s ease;

  &:hover {
    color: rgb(var(--v-theme-primary));
    transform: translateX(4px);
  }

  &:focus-visible {
    outline: 2px solid rgb(var(--v-theme-primary));
    outline-offset: 2px;
  }
}

// Make sure divider extends full width
:deep(.v-divider) {
  opacity: 0.4;
  width: 100%;
}

// Footer legal links
.grey-darken-1-text {
  color: rgba(0, 0, 0, 0.6);

  &:hover {
    color: rgb(var(--v-theme-primary));
  }
}

// Ensure proper alignment at various breakpoints
@media (max-width: 599px) {
  :deep(.v-col) {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}
</style>
